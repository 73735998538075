<template>
    <report-summary-card
        report-title="Monthly Projected Income"
        :loading="loading"
        report-link=""
    >
        <template v-slot:chart style="height: 100%;">
            <projected-income-bars
                :data="data"
                :view-by="viewBy"
                :id="id"
            ></projected-income-bars>
        </template>
    </report-summary-card>
</template>

<script>
    import ReportSummaryCard from '../dashboard/ReportSummaryCard';
    import ProjectedIncomeBars from './ProjectedIncomeBars'

    export default {
        name: "ProjectedIncomeBarCard",
        props: [
            'data', 'viewBy', 'id'
        ],
        components: {
            ReportSummaryCard, ProjectedIncomeBars
        },
        data() {
            return {
                viewByNameMap: {
                    credit_rating_det: 'Rating',
                    sub_category: 'Subcategory',
                    maturity: 'Maturity'
                }
            }
        },
        computed: {
             loading() {
                return this.$store.getters.consPosLoading
            }
        }
    }
</script>

<style scoped>

</style>