<template>
    <report-summary-card
        report-title="Projected Total Income"
        :loading="loading"
        report-link=""
    >
        <template v-slot:header>
            <v-row
                no-gutters
                class="mt-8"
            >
                <v-col class="text-center" cols="12" sm="6">
                    <span class="headline primary-text" v-text="formattedAvgMonthlyPI"></span><br>
                    <span class="subtitle-1">Avg Monthly Projected Income</span>
                </v-col>
                <v-col class="text-center mt-4 mt-sm-0" cols="12" sm="6">
                    <span class="headline primary-text" v-text="formattedTotalPI"></span><br>
                    <span class="subtitle-1">Projected Income</span>
                </v-col>
            </v-row>
        </template>
        <template v-slot:table>
            <v-data-table
                :headers="tableHeaders"
                disable-sort
                disable-pagination
                hide-default-footer
                dense
                :items="formattedData"
                :mobile-breakpoint="0"
            ></v-data-table>
            <v-divider></v-divider>
        </template>
    </report-summary-card>
</template>

<script>
    import ReportSummaryCard from '../dashboard/ReportSummaryCard';
    import {aggregateProjectedIncomeByMonth} from "../../utils/data.utils";
    import {currencyFormatter} from "../../utils/format.utils";

    export default {
        name: "ProjectedIncomeTotalTableCard",
        props: [
            'data', 'viewBy'
        ],
        components: {
            ReportSummaryCard
        },
        data() {
            return {
                viewByNameMap: {
                    credit_rating_det: 'Rating',
                    sub_category: 'Subcategory',
                    maturity: 'Maturity'
                },
                tableHeaders: [
                    {text: '', value: 'month', align: 'center', width: '50%'},
                    {text: '', value: 'pi', align: 'center', width: '50%'},
                ]
            }
        },
        computed: {
             loading() {
                return this.$store.getters.consPosLoading
            },
            monthList(){
                let nextTwelveMonths = this.$store.getters.nextTwelveMonths.slice()
                nextTwelveMonths.push('Other')
                return nextTwelveMonths
            },
            aggregateData(){

                 let newData
                 if (this.projectedIncomeChartFilter !== null) {
                     newData = this.data.filter(x => x[this.viewBy] === this.projectedIncomeChartFilter);
                 } else {
                     newData = this.data.map(x => x)
                 }

                 return aggregateProjectedIncomeByMonth(newData)
            },
            formattedData(){
                 let newData = [];
                 for (let i=0; i < 13; i++){
                     let newRecord = {
                         month: this.monthList[i],
                         pi: currencyFormatter.format(this.aggregateData[i])
                     };
                     newData.push(newRecord)
                 }
                 return newData
            },
            totalProjectedIncome(){
                 let total = 0;
                 this.aggregateData.forEach(item => {
                     total += item
                 });
                return total
            },
            formattedTotalPI(){
                return currencyFormatter.format(this.totalProjectedIncome)
            },
            avgMonthlyPI(){
                 return this.totalProjectedIncome / 12
            },
            formattedAvgMonthlyPI(){
                 return currencyFormatter.format(this.avgMonthlyPI)
            },
            projectedIncomeChartFilter(){
                return this.$store.getters.projectedIncomeChartFilter
            },
        }
    }
</script>

<style scoped>
.w-80{
    width: 80% !important;
}

.w-70{
    width: 70% !important;
}

.w-60{
    width: 60% !important;
}

</style>