<template>
    <div class="chart-box mt-4 clickable" >
        <canvas :id="'pi-bars-' + id"></canvas>
    </div>
</template>

<script>
    import {aggregateProjectedIncomeByAgg} from "../../utils/data.utils";
    import {currencyFormatter} from "../../utils/format.utils";
    import {sortBy} from "../../utils/data.utils";
    import Chart from 'chart.js'

    export default {
        name: "ProjectedIncomeBars",
        props: [
            'data', 'viewBy', 'id'
        ],
        data() {
            return {
                chartFormattedColors: [],
                chart: null,
            }
        },
        mounted() {
            let ctx = document.getElementById('pi-bars-' + this.id);
            ctx.height = 170;
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.chartFormattedLabels,
                    datasets: this.aggregateData
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: {
                        position: 'bottom',
                        onClick: this.legendCallback
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false
                            },
                            stacked:true
                        }],
                        yAxes: [{
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                callback: (value) => {
                                    return '$' + (value/1000).toFixed(1) + 'k'
                                }
                            },
                            stacked:true
                        }]
                    },

                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                let dataset = data.datasets[tooltipItem.datasetIndex];
                                let currentValue = dataset.data[tooltipItem.index];
                                return  dataset.label +': ' + currencyFormatter.format(currentValue);
                            }
                        }
                    }
                }
            });

            ctx.onclick = (evt) => {

                    let activePoints = this.chart.getElementAtEvent(evt);

                    // If they click directly on a bar
                    if (activePoints[0]){

                        // Set the filter
                        let datasetIndex = activePoints[0]['_datasetIndex'];
                        let label = this.chart.data.datasets[datasetIndex].label
                        this.$store.dispatch('setProjectedIncomeChartFilter', label);

                    // if they clicked anywhere but a bar
                    } else {

                        let mousePoint = Chart.helpers.getRelativePosition(evt, this.chart.chart);
                        let minYPointOnChart = this.chart.scales['y-axis-0'].bottom;
                        let yClickPoint = mousePoint.y;

                        // If they click in the chart area (but not on a bar)
                        if (yClickPoint <= minYPointOnChart){
                            // Set it back
                            this.$store.dispatch('setProjectedIncomeChartFilter', null);
                        }

                        // otherwise do nothing, let legend handle click event

                    }
                }

            this.chartFormattedColors = this.chartFormattedColorsCalc.map(x => x)
            this.chart.update()
        },
        watch: {
            aggregateData: function () {

                this.chart.data.labels = this.chartFormattedLabels;
                this.chart.data.datasets = this.aggregateData;
                this.chartFormattedColors = this.chartFormattedColorsCalc.map(x=> x)
                this.chart.update()
                this.chart.resize()
            },
            projectedIncomeChartFilter: function (){
                if (this.projectedIncomeChartFilter !== null){

                    // Change the colors
                    let datasets = this.chart.data.datasets
                    for (let i = 0; i < datasets.length; i++) {
                        if (datasets[i].label !== this.projectedIncomeChartFilter) {
                            datasets[i].backgroundColor = 'lightgray'
                        } else {
                            datasets[i].backgroundColor = this.chartFormattedColors[i]
                        }
                    }

                } else {
                    let datasets = this.chart.data.datasets
                    for (let i = 0; i < datasets.length; i++) {
                        datasets[i].backgroundColor = this.chartFormattedColors[i]
                    }
                }

                this.chart.update()
                this.$nextTick(() => {
                    this.chart.update()
                })
            }
        },
        computed: {
            sortedData(){
                let newData = this.data.map((x) => x);
                newData.forEach(o => {
                    o.sort_field = o.sorting[this.viewBy]
                })

                let sortFunc = sortBy('sort_field', this.viewBy);

                return newData.sort(sortFunc)
            },
            aggregateData(){
                let newData = this.sortedData.map(x => x)
                return aggregateProjectedIncomeByAgg(newData, this.viewBy).map((x) => x)
            },

            chartFormattedLabels(){
                let nextTwelveMonths = this.$store.getters.nextTwelveMonths.slice()
                nextTwelveMonths.push('Other')
                return nextTwelveMonths
            },
            projectedIncomeChartFilter(){
                return this.$store.getters.projectedIncomeChartFilter
            },
            chartFormattedColorsCalc(){
                let colors = [];
                this.aggregateData.map(x => x).forEach(record => {
                    colors.push(record.backgroundColor)
                });
                return colors
            },
        },
        methods: {
            legendCallback(e, legendItem) {
                this.$store.dispatch('setProjectedIncomeChartFilter', legendItem.text);
            }
        }
    }
</script>

<style scoped>

</style>