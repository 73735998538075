<template>
    <report-summary-card
        report-title="Projected Income Table Details"
        :loading="loading"
        report-link=""
        :show-search="false"
        @search="searchPositions"
        show-export
        :export-data="exportData"
        export-file-name="projected_income_table_details.csv"
    >
        <template v-slot:table>
            <div
                @mouseleave="showTooltip = false"
            >
                <v-data-table
                    :headers="reactiveTableHeaders"
                    :custom-sort="customSort"
                    :items="formattedData"
                    :search="searchText"
                    no-data-text="No positions found."
                    :mobile-breakpoint="0"
                    :options.sync="options"
                    fixed-header
                    :height="formattedData.length > 10 ? 525 : 50 + formattedData.length * 52.5"
                >
                    <template v-slot:item="{item, headers}">
                        <tr
                            @mouseenter="showTooltip = true"
                        >
                            <td
                                :class="header.align ? 'text-'+header.align : ''"
                                :key="index" v-for="(header, index) in headers"
                                @click="getToolTipBreakdown(item.macro_category, item.position_name)"
                                @mouseover="getToolTipBreakdown(item.macro_category, item.position_name)"
                                @mousemove="updateToolTipPosition"
                            >

                                <span
                                    class='font-weight-bold primary-text'
                                    v-if="header.value==='total'"
                                >
                                    {{ item[header.value] }}
                                </span>
                                <span
                                    v-else
                                >
                                    {{ item[header.value] }}
                                </span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
            <extended-table-tool-tip
                :data="tooltipData"
                :position-x="positionX"
                :position-y="positionY"
                :projected-income="true"
                v-if="showTooltip"
            ></extended-table-tool-tip>
        </template>
    </report-summary-card>
</template>

<script>
    import ReportSummaryCard from '../dashboard/ReportSummaryCard';
    import {currencyFormatter} from "../../utils/format.utils";
    import {disaggForTooltip, sortBy} from "../../utils/data.utils";
    import ExtendedTableToolTip from './ExtendedTableToolTip'

    export default {
        name: "ProjectedIncomeExtendedTableCard",
        props: [
            'data', 'viewBy'
        ],
        components: {
            ReportSummaryCard, ExtendedTableToolTip
        },
        data() {
            return {
                options: {},
                searchText: '',
                viewByNameMap: {
                    credit_rating_det: 'Rating',
                    sub_category: 'Subcategory',
                    maturity: 'Maturity'
                },
                tableHeaders: [
                    {text: 'Asset Class', value: 'category', width: "130"},
                    {text: 'Subcategory', value: 'sub_category', width: "130"},
                    {text: 'Position', value: 'position_name', width: "400"},
                ],
                numbers: [
                    "pi_month_one",
                    "pi_month_two",
                    "pi_month_three",
                    "pi_month_four",
                    "pi_month_five",
                    "pi_month_six",
                    "pi_month_seven",
                    "pi_month_eight",
                    "pi_month_nine",
                    "pi_month_ten",
                    "pi_month_eleven",
                    "pi_month_twelve",
                    "other"
                ],
                showTooltip: false,
                tooltipData: [],
                positionY: 0,
                positionX: 0
            }
        },
        watch: {
            viewBy: function () {
                this.options.sortBy = [];
                this.options.sortDesc = [];
            }
        },
        methods: {
            searchPositions(searchText){
                this.searchText = searchText
            },
            getToolTipBreakdown(macroCategory, positionName){
                this.updateToolTipPosition();
                this.tooltipData = disaggForTooltip(macroCategory, positionName, this.data.map(x=>x));
            },
            updateToolTipPosition(){
                let posY = event.clientY;
                posY -= 32; // Table header
                posY -= (24 * this.tooltipHeight); // table body height
                posY -= 15; // Buffer
                this.positionY = posY;
                this.positionX = event.clientX;
            },
            customSort(items, index, isDescending) {
                if (index.length === 0) {
                    return items
                }
                const isDesc = !isDescending[0];  // reversed so that ascending sort is really descending
                const sortField = index[0];
                const keys = Object.keys(this.formattedData[0] || {});
                const hasRawField = keys.includes(sortField + '_raw');
                let sortFunc;
                if (hasRawField) {
                    const rawField = sortField + '_raw';
                    if (!isDesc) {
                        sortFunc = sortBy(rawField);
                    } else {
                        sortFunc = sortBy({name: rawField, reverse: true})
                    }
                } else {
                    if (!isDesc) {
                        sortFunc = sortBy(sortField);
                  } else {
                        sortFunc = sortBy({name: sortField, reverse: true})
                  }
                }

                return items.sort(sortFunc)
            }
        },
        computed: {
            exportData() {
                let exportData = []

                let data
                if (this.projectedIncomeChartFilter !== null) {
                    data = this.data.filter(x => x[this.viewBy] === this.projectedIncomeChartFilter);
                } else {
                    data = this.data.slice()
                }

                data.forEach(o => {
                    const totalMonthlyIncome = (
                        o.pi_month_one + o.pi_month_two + o.pi_month_three + o.pi_month_four + o.pi_month_five +
                        o.pi_month_six + o.pi_month_seven + o.pi_month_eight + o.pi_month_nine + o.pi_month_ten +
                        o.pi_month_eleven + o.pi_month_twelve
                    )
                    const otherProjectedIncome = o.projected_income - totalMonthlyIncome

                    const newObject = {
                        'Asset Class': o.category,
                        'SubCategory': o.sub_category,
                        'ISIN/CUSIP': o.isin,
                        'Position Name': o.position_name,
                        'Account': o.portfolio
                    }

                    for(let i=0; i < 12; i++){
                        newObject[this.monthList[i]] = o[this.numbers[i]].toFixed(2)
                    }

                    newObject['Other'] = otherProjectedIncome.toFixed(2)
                    newObject['Total'] = (otherProjectedIncome + totalMonthlyIncome).toFixed(2)
                    newObject['Current Yield'] = o.usd_amount ? (otherProjectedIncome + totalMonthlyIncome / o.usd_amount * 100).toFixed(2) + '%' : '0.00%'
                    exportData.push(newObject)
                })

                return exportData
            },
            loading() {
                return this.$store.getters.consPosLoading
            },
            tooltipHeight(){
                return this.tooltipData.length;
            },
            reactiveTableHeaders(){

                 let newTableHeaders = this.tableHeaders.map(x => x);
                 for(let i=0; i < 13; i++){
                     let newHeader = {
                         text: this.monthList[i],
                         value: this.numbers[i],
                         align: 'end',
                         width: 120
                     };
                     newTableHeaders.push(newHeader);
                 }

                 const totalHeader = {
                         text: 'Total',
                         value: 'total',
                         align: 'end',
                            width: 150
                     };

                 newTableHeaders.push(totalHeader);

                 const currentYieldHeader = {
                         text: 'Current Yield',
                         value: 'current_yield',
                         align: 'end',
                            width: 150
                     };

                 newTableHeaders.push(currentYieldHeader);

                 return newTableHeaders

            },
            monthList(){
                let nextTwelveMonths = this.$store.getters.nextTwelveMonths.slice()
                nextTwelveMonths.push('Other')
                return nextTwelveMonths
            },
            aggregatedData(){

                let data
                if (this.projectedIncomeChartFilter !== null) {
                    data = this.data.filter(x => x[this.viewBy] === this.projectedIncomeChartFilter);
                } else {
                    data = this.data.slice()
                }

                return [...data.reduce((r, o) => {
                    const key = o.macro_category + '-' + o.category + '-' + o.sub_category + '-' + o.position_name;

                    const item = r.get(key) || Object.assign({}, o, {
                        pi_month_one: 0,
                        pi_month_two: 0,
                        pi_month_three: 0,
                        pi_month_four: 0,
                        pi_month_five: 0,
                        pi_month_six: 0,
                        pi_month_seven: 0,
                        pi_month_eight: 0,
                        pi_month_nine: 0,
                        pi_month_ten: 0,
                        pi_month_eleven: 0,
                        pi_month_twelve: 0,
                        total: 0,
                        other: 0,
                        usd_amount: 0,
                        sort_order_category: 1,
                        sort_order_sub_category: 1
                    });

                    const totalMonthlyIncome = (
                        o.pi_month_one + o.pi_month_two + o.pi_month_three + o.pi_month_four + o.pi_month_five +
                        o.pi_month_six + o.pi_month_seven + o.pi_month_eight + o.pi_month_nine + o.pi_month_ten +
                        o.pi_month_eleven + o.pi_month_twelve
                    )

                    const otherProjectedIncome = o.projected_income - totalMonthlyIncome < 0 ? 0 : o.projected_income - totalMonthlyIncome

                    item.pi_month_one += o.pi_month_one;
                    item.pi_month_two += o.pi_month_two;
                    item.pi_month_three += o.pi_month_three;
                    item.pi_month_four += o.pi_month_four;
                    item.pi_month_five += o.pi_month_five;
                    item.pi_month_six += o.pi_month_six;
                    item.pi_month_seven += o.pi_month_seven;
                    item.pi_month_eight += o.pi_month_eight;
                    item.pi_month_nine += o.pi_month_nine;
                    item.pi_month_ten += o.pi_month_ten;
                    item.pi_month_eleven += o.pi_month_eleven;
                    item.pi_month_twelve += o.pi_month_twelve;
                    item.total += totalMonthlyIncome;
                    item.total += otherProjectedIncome;
                    item.other += otherProjectedIncome;
                    item.usd_amount += o.usd_amount;
                    item.sort_order_sub_category = o.sorting.sub_category;
                    item.sort_order_category = o.sorting.category;

                    return r.set(key, item);

                }, new Map).values()];

            },

            sortedData(){
                let newData = this.aggregatedData.map(x => x);
                let sortFunc = sortBy(
                    'sort_order_category', 'category',
                    'sort_order_sub_category', 'sub_category',
                    {name: 'total', reverse: true});
                return newData.sort(sortFunc)
            },

            formattedData(){
                 let newData = [];
                 this.sortedData.forEach(record => {
                     let newRecord = {
                         macro_category: record.macro_category,
                         category: record.category,
                         sub_category: record.sub_category,
                         position_name: record.position_name,
                         total: currencyFormatter.format(record.total),
                         total_raw: record.total,
                         current_yield_raw: record.usd_amount ? record.total / record.usd_amount : 0,
                         current_yield: ((record.usd_amount ? record.total / record.usd_amount : 0) * 100).toFixed(2) + '%'
                     };

                     this.numbers.forEach(number => {
                        newRecord[number] =  currencyFormatter.format(record[number]);
                        newRecord[number + '_raw'] =  record[number];
                     });

                     newData.push(newRecord);
                 });

                 return newData
            },
            projectedIncomeChartFilter(){
                return this.$store.getters.projectedIncomeChartFilter
            },
            tableHeight(){
                const itemsPerPage = this.options.itemsPerPage
                // selected to see ALL records
                if (itemsPerPage === -1){
                    if (this.formattedData.length === 0) {
                        return 100
                      } else if (this.formattedData.length <= 25) {
                        return 65 + this.formattedData.length * 48
                      } else {
                        return 65 + 25 * 48 // max table size
                      }
                } else {
                    return 65 + itemsPerPage * 48
                }

            }
        }
    }
</script>

<style scoped>
.w-80{
    width: 80% !important;
}

.w-70{
    width: 70% !important;
}

.w-60{
    width: 60% !important;
}

>>> .v-data-table-header th.desc .v-data-table-header__icon {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

>>> .v-data-table-header th.asc .v-data-table-header__icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

>>> .v-data-table-header th:hover:not(.desc):not(.asc) .v-data-table-header__icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
</style>